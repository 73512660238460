import Vue from 'vue'
import App from './App.vue'
import $ from "jquery";

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')


function headerMenuMobile() {
  var $headerMenu = $('.vx-nav-md');

  $('.vx-md-btn').on('click', function(ev) {
    var $target = $(ev.currentTarget);
    $target.toggleClass('open');
    $headerMenu.toggleClass('active');
  });

  function closeMenu () {
    $('.vx-md-btn').removeClass('open');
    $headerMenu.removeClass('active');
  }

  $(document).on('click', function (ev) {
    var $clickedEl = $(ev.target);

    var isHamburger = $clickedEl.hasClass('vx-md-btn');
    var isHamburgerParent = $clickedEl.closest('.vx-md-btn').length;

    if(!(isHamburger || isHamburgerParent)){closeMenu()}
  });

  $(window).on('resize', closeMenu);
}

headerMenuMobile();

function scrollNav() {
  $('a').click(function(){
    $(".active").removeClass("active");
    $(this).addClass("active");

    $('html, body').stop().animate({
      scrollTop: $($(this).attr('href')).offset().top - 120
    }, 10);
    return false;
  });
}
scrollNav();

var coll = document.getElementsByClassName("collapse-btn");
var i;

for (i = 0; i < coll.length; i++) {
  coll[i].addEventListener("click", function() {
    this.classList.toggle("active");
    var content = this.nextElementSibling;
    if (content.style.maxHeight){
      content.style.maxHeight = null;
    } else {
      content.style.maxHeight = content.scrollHeight + "px";
    }
  });
}